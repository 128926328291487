import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './EdgeMeComponent.css'; // Import CSS file

import { secondsToHms, getRandomInt, getProgress } from './Helpers.js'

const EdgeMeComponent = () => {
    const [gameState, setGameState] = useState('MENU');
    const [finished, setFinished] = useState(true);
    const [targetDuration, setTargetDuration] = useState(900);
    const [targetDifficulty, setTargetDifficulty] = useState(2);
    const [currentPhase, setCurrentPhase] = useState(1);
    const [targetAllowance, setTargetAllowance] = useState(75);
    const [hideMainProgress, setHideMainProgress] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [waitElaspedTime, setWaitElaspedTime] = useState(0);
    const [waitDuration, setWaitDuration] = useState(1);
    const [currentRandom, setCurrentRandom] = useState(1);
    const goWordList = ["GO", "Yank", "Wank it!", "Jerk Off", "Good boy~", "fap"];
    const waitWordList = ["Wait!", "Stop!", "Pause", "Hands off!", "No cumming...", "No Touching!"];
    const difficultyValues = useMemo(() => ({
        1: {
            1: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
            2: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
        },
        2: {
            1: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
            2: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
        },
        3: {
            1: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
            2: {"GO":{min:5,max:45}, "WAIT":{min:1,max:25}},
        },
    }),[]);
    const handleDurationChange = (event) => {
      setTargetDuration(parseInt(event.target.value));
    };
    /*const handleDifficultyChange = (event) => {
      setTargetDifficulty(parseInt(event.target.value));
    };*/
    const handleAllowanceChange = (event) => {
      setTargetAllowance(parseInt(event.target.value));
    };
    const handleHideMainProgressChange = (event) => {
        setHideMainProgress(event.target.checked);
    };

    const updateGameState = useCallback((newState, finish=false) => {
        let wait = 0;
        setGameState(newState);
        setWaitElaspedTime(0);
        if (newState === "GO" || newState === "WAIT") {
            wait = getRandomInt(difficultyValues[targetDifficulty][currentPhase][newState]['min'],
            difficultyValues[targetDifficulty][currentPhase][newState]['max']);
            setWaitDuration(wait);
        }
        setCurrentRandom(Math.random());
        setFinished(finish);
        console.log('state: %s\n*diff: %d\n*phase: %d\nwait: %d\nelapsed: %d\ntarget: %d', newState, targetDifficulty, currentPhase, wait, elapsedTime, targetDuration);
    }, [difficultyValues, targetDifficulty, currentPhase, elapsedTime, targetDuration])

    const startGame = () => {
        setCurrentPhase(1);
        setTargetDifficulty(2);
        updateGameState('GO');
      };

    useEffect(() => {
        let interval;

        if(!finished) {
            if(elapsedTime >= targetDuration) {
                setFinished(true);
                if (getRandomInt(0,100) <= targetAllowance) {
                    setGameState('FINISH');
                } else {
                    setGameState('NOFINISH');
                }
                return;
            }

            switch(gameState) {
                case 'GO':
                    if (waitElaspedTime >= waitDuration) {
                        updateGameState('WAIT');
                    }
                    break;
                case 'WAIT':
                    if (waitElaspedTime >= waitDuration) {
                        updateGameState('GO');
                    };
                    break;
                default:
            }

            interval = setInterval(() => {
                setElapsedTime((prevElapsedTime) => prevElapsedTime + .1);
                setWaitElaspedTime((prevElapsedTime) => prevElapsedTime + .1);
              }, 100);
        }
        return () => {
          clearInterval(interval);
        };
    }, [updateGameState, elapsedTime, targetDuration, gameState, finished, targetAllowance, targetDifficulty, currentPhase, currentRandom, waitDuration, waitElaspedTime]);

    const progress = getProgress(elapsedTime, targetDuration);
    const waitprogress = getProgress(waitElaspedTime, waitDuration);
    switch(gameState) {
        case 'MENU':
            return (
                <div className="container">
                    <form id="choose">
                        <label htmlFor="duration">Duration:</label>
                        <input
                        type="range"
                        id="duration"
                        name="duration"
                        min="30"
                        max="7200"
                        step="1"
                        value={targetDuration}
                        onChange={handleDurationChange}
                        className="form-range"
                        />
                        <p className="slider-info"> {secondsToHms(targetDuration)}</p>
                        <br/>
                        {/*
                        <label htmlFor="difficulty">Difficulty:</label>
                        <input
                        type="range"
                        id="difficulty"
                        name="difficulty"
                        min="1"
                        max="3"
                        step="1"
                        value={targetDifficulty}
                        onChange={handleDifficultyChange}
                        className="form-range"
                        />
                        <p className="slider-info"> Level {targetDifficulty}</p>
                        <br/>
                        */}
                    
                        <label htmlFor="allowance">Allowance:</label>
                        <input
                        type="range"
                        id="allowance"
                        name="allowance"
                        min="0"
                        max="100"
                        step="1"
                        value={targetAllowance}
                        onChange={handleAllowanceChange}
                        className="form-range"
                        />
                        <p className="slider-info"> {targetAllowance}% Chance</p>
                        <br/>
                        <label htmlFor="hideMainProgress">Hide Main Progress Bar:</label>
                        <input
                        type="checkbox"
                        id="hideMainProgress"
                        name="hideMainProgress"
                        checked={hideMainProgress}
                        onChange={handleHideMainProgressChange}
                        className="checkbox-input"
                        />
                        <br/>
                        <p>
                        {/*Level {targetDifficulty}<br/>*/}
                        For {secondsToHms(targetDuration)}<br/>
                        with a {targetAllowance}% chance of cumming!</p>
                        <br/>
                        <button
                        className="btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            startGame();
                        }}
                        >
                        Start Edging
                        </button>
                    </form>
                </div>
            )
        case 'GO':
            return (
                <div className="container go">
                    <h1>{goWordList[Math.floor(currentRandom * goWordList.length)]}</h1>
                    <div className="progress">

                    <div
                        className="progress-bar wait-bar"
                        role="progressbar"
                        style={{ width: `${waitprogress}%` }}
                        aria-valuenow={waitprogress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                    <br/>
                    {hideMainProgress === false && (
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                    )}
                    </div>
                </div>
            )
        case 'WAIT':
            return (
                <div className="container wait">
                    <h1>{waitWordList[Math.floor(currentRandom * waitWordList.length)]}</h1>
                    <div className="progress">
                    <div
                        className="progress-bar wait-bar"
                        role="progressbar"
                        style={{ width: `${waitprogress}%` }}
                        aria-valuenow={waitprogress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                    <br/>
                    {hideMainProgress === false && (
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                    </div>
                    )}
                    </div>
                </div>
            )
        case 'FINISH':
            return (
                <div className="container finish">
                    <h1>💦💦💦 CUM! 💦💦💦</h1>
                    <p>You're now allowed to cum! :3</p>
                </div>
            )
        case 'NOFINISH':
            return (
                <div className="container nofinish">
                    <h1>Game Over!</h1>
                    <p>Congratulations, you've completed! But sorry, no cummies this time ;3</p>
                </div>
            )
        default:
            return (
                <div>Invalid Case</div>
            )

    }

};

export default EdgeMeComponent;