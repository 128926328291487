import React from 'react';
//import GameComponent from './GameComponent';
import EdgeMeComponent from './EdgeMeComponent';
import './App.css'; // Import CSS file

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1><a className="App-link" href='/'>🍆 PleaseEdge.Me 💦</a></h1>
      </header>
      <div className='App-container'>
        <EdgeMeComponent />
      </div>
    </div>
  );
}

export default App;